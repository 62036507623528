<template>
  <a-spin :spinning="spinning">
    <a-row>
      <a-col :span="24" :lg="24" :xl="24" class="mb-24">
        <CardBillingInfo :groupInfo="groupInfo" ></CardBillingInfo>
      </a-col>
    </a-row>
    <a-row :gutter="24">
      <a-col :span="24" :lg="12" :xl="6" class="mb-24" v-for="(stat, index) in stats" :key="index">
        <WidgetCounter :title="stat.title" :value="stat.value" :prefix="stat.prefix" :suffix="stat.suffix"
          :icon="stat.icon" :status="stat.status"></WidgetCounter>
      </a-col>
    </a-row>
    <!-- <a-row :gutter="24" type="flex" align="stretch">
      <a-col :span="24" :lg="12" class="mb-24">
        <CardBarChart v-if="barChartData.labels.length" :data="barChartData" type="msg"></CardBarChart>
      </a-col>
      <a-col :span="24" :lg="12" class="mb-24">
        <CardBarChart v-if="barChartData1.labels.length" :data="barChartData1" type="user"></CardBarChart>
      </a-col>
    </a-row> -->
  </a-spin >
</template>
  
<script>
import { getWxworkGroupDetail } from '../http/api';
import CardBarChart from '../components/Cards/CardBarChart';
import CardLineChart from '../components/Cards/CardLineChart';
import WidgetCounter from '../components/Widgets/WidgetCounter';
import CardAuthorTable from '../components/Cards/CardAuthorTable';
import CardBillingInfo from "../components/Cards/CardBillingInfo"
const tableColumns = [{
  title: '群名',
  dataIndex: 'group_name',
}, {
  title: '群主',
  dataIndex: 'leader',
  scopedSlots: { customRender: 'leader' },
}, {
  title: '群成员数',
  dataIndex: 'total_users',
}, {
  title: '近七天消息数',
  dataIndex: 'last_seven_msg_count',
}, {
  title: '操作',
  scopedSlots: { customRender: 'editBtn' },
  width: 50,
}]

export default ({
  components: {
    CardBarChart,
    CardLineChart,
    WidgetCounter,
    CardAuthorTable,
    CardBillingInfo
  },
  data() {
    return {
      spinning: false,
      stats: [],
      groupInfo: {}
    }
  },
  mounted() {
    this.loading = true
    this.getWxworkGroupDetail()

  },
  methods: {
    getWxworkGroupDetail() {
      this.spinning = true
      let groupName = this.$route.query.groupName
      let nickname = this.$route.query.nickname || null
      let sevenDaysAgo = new Date().getTime() - 7 * 24 * 60 * 60 * 1000
      let zeroTime = new Date(new Date().toLocaleDateString()).getTime()
      getWxworkGroupDetail({
        variables: {
          groupName,
          aStart: Math.floor(sevenDaysAgo / 1000),
          bStart: Math.floor(zeroTime / 1000),
          sender: nickname
        }
      }).then(res => {
        console.log(res)
        if (res.errors) {
          this.$message.error(res.errors[0].message)
          return
        }
        this.groupInfo = res.data.a.group
        this.groupInfo.name = groupName
        console.log(this.groupInfo)
        // 近7天消息数
        let sevenDaysMsgCount = res.data.b.group.msgCount
        // 今日消息数
        let todayMsgCount = res.data.c.group.msgCount
        // 近7天管理员发言数
        let sevenDaysAdminMsgCount = nickname ? res.data.d.group.msgCount : 0
        // 今日管理员发言数
        let todayAdminMsgCount = nickname ? res.data.e.group.msgCount : 0

        this.stats = [{
          title: "今日消息数",
          value: +todayMsgCount,
        }, {
          title: "近7天消息数",
          value: +sevenDaysMsgCount,
        }, {
          title: "今日负责人发言数",
          value: +todayAdminMsgCount,
        }, {
          title: "近7天负责人发言数",
          value: +sevenDaysAdminMsgCount,
        }]
        this.spinning = false
      }).catch(() => {
        this.spinning = false
      })
    },
  },
})

</script>
  
<style lang="scss">
.mb-24 {
  margin-bottom: 24px;
}
</style>